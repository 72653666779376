import React from 'react'
import '../css/Loading.css'

export default function Loading() {
  return (
    <div className='lod'>
      <div className="loader"></div>
    </div>
  )
}
